import { Box, FormControl, Link, TextField } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { cgBaseUrl } from 'utils/codingameService'

import { AuthenticationButton } from '../../authentication/components/AuthenticationButton/AuthenticationButton'
import { track } from '../../main/coderpad_analytics'
import { secondaryLinkTrack } from '../LoginForm/LoginForm'
import { useStyles } from './EmailForm.style'

interface IEmailFormProps {
  disabled?: boolean
  email: string
  handleEmailChange: (targetEmail: string) => void
  handleContinue: () => void
  onErrorChange: (error: boolean) => void
  loadingQuery: boolean
  idpEnabled?: boolean
  isCodingameUserOnly: boolean
}

export const EmailForm: React.FC<React.PropsWithChildren<IEmailFormProps>> = ({
  disabled = false,
  email,
  handleEmailChange,
  handleContinue,
  onErrorChange,
  loadingQuery,
  idpEnabled = false,
  isCodingameUserOnly,
}) => {
  const [displayCodingameUserOnlyMessage, setDisplayCodingameUserOnlyMessage] = useState(false)
  const styles = useStyles()
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onTouched',
  })

  const {
    field: { ref, onChange, onBlur },
    meta: { invalid, isDirty },
  } = useController({
    name: 'email',
    control,
    defaultValue: email,
    rules: {
      required: true,
      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    },
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e)
    handleEmailChange(e.target.value)
    setDisplayCodingameUserOnlyMessage(false)
  }

  useEffect(() => {
    track('login step-1 email entry viewed')
  }, [])

  const handleEmailSubmit = handleSubmit(() => {
    if (displayCodingameUserOnlyMessage) {
      return
    }

    handleContinue()
    track('login step-1 email submitted')
  })

  useEffect(() => {
    onErrorChange(invalid != null)
  }, [invalid, onErrorChange])

  useEffect(() => {
    if (!isDirty && email.length > 0) {
      setValue('email', email, { shouldDirty: true })
    }
  }, [email, isDirty, setValue])

  useEffect(() => {
    setDisplayCodingameUserOnlyMessage(isCodingameUserOnly)
  }, [isCodingameUserOnly])

  const emailHelperText = displayCodingameUserOnlyMessage ? (
    <>
      An old account exists with this email, please login <a href={`${cgBaseUrl}/login`}>here</a>.
    </>
  ) : invalid ? (
    'Valid email is required'
  ) : null

  return (
    <Box component="form" display="flex" flex={1} onSubmit={handleEmailSubmit}>
      <FormControl fullWidth>
        <TextField
          disabled={disabled}
          id="email"
          name="email"
          value={email}
          placeholder="Work Email"
          variant="outlined"
          size="small"
          onChange={handleChange}
          onBlur={onBlur}
          inputRef={ref}
          error={invalid || displayCodingameUserOnlyMessage}
          helperText={emailHelperText}
          InputProps={{
            autoFocus: true,
          }}
          sx={{
            transition: 'margin-top 300ms', // Collapse default transition duration
          }}
        />

        <AuthenticationButton
          type="submit"
          isLoading={loadingQuery || idpEnabled}
          disabled={email.length === 0 || loadingQuery || idpEnabled || disabled}
          sx={{ marginTop: '24px' }}
        >
          Continue
        </AuthenticationButton>

        <Box className={styles.instructions}>
          <Link
            href="/confirmation/new"
            underline="hover"
            onClick={() => secondaryLinkTrack(1, 'confirmation instructions')}
          >
            Did not receive confirmation instructions?
          </Link>
          <Link
            href="/unlock/new"
            underline="hover"
            onClick={() => secondaryLinkTrack(1, 'unlock instructions')}
          >
            Did not receive unlock instructions?
          </Link>
        </Box>
      </FormControl>
    </Box>
  )
}

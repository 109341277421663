import PasswordRulesAdornment from '@codinpad/shared-components/components/adornments/PasswordRulesAdornment.js'
import { checkPassword } from '@codinpad/shared-components/components/passwordRulesTooltip/rules.js'
import { Box, styled, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useSearchParams } from 'utils'
import { useFetch } from 'utils/fetch/useFetch'

import { AuthenticationButton } from '../../authentication/components/AuthenticationButton/AuthenticationButton'

const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const NewPasswordForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<React.ReactNode>()
  const form = useRef(null)
  const fetcher = useFetch()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('reset_password_token') || ''

  const onSubmitFailed = () => {
    setError(
      <>
        This link is invalid or has expired, please request a new password reset{' '}
        <a href="new">here</a>.
      </>
    )
    setIsLoading(false)
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.current) {
      return
    }

    try {
      setError(undefined)
      setIsLoading(true)
      const data = new FormData(form.current)
      const res = await fetcher('/password', { method: 'PUT', body: data })
      const json = await res.json()

      if (res.ok && json.urlRedirect) {
        window.location = json.urlRedirect
      } else {
        onSubmitFailed()
      }
    } catch (e) {
      onSubmitFailed()
    }
  }

  const passwordRequirements = {
    length: 8,
    lowercase: true,
    uppercase: true,
    symbol: true,
  }
  const isPasswordValid = checkPassword(password, passwordRequirements)

  return (
    <Container>
      <Box sx={{ width: '400px', margin: '0 auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
          <Typography variant="h2">Reset password</Typography>
        </Box>
        <form ref={form} id="new_user" onSubmit={onSubmit}>
          <input type="hidden" name="user[reset_password_token]" value={token} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="New Password"
              placeholder="New Password"
              size="small"
              id="user_password"
              name="user[password]"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
              helperText={error}
              type="password"
              InputProps={{
                endAdornment: (
                  <PasswordRulesAdornment
                    password={password}
                    requirements={passwordRequirements}
                    locale="en"
                  >
                    Password Rules
                  </PasswordRulesAdornment>
                ),
              }}
            />
            <AuthenticationButton
              type="submit"
              isLoading={isLoading}
              disabled={!isPasswordValid || isLoading}
              sx={{ marginTop: '24px' }}
            >
              Reset password
            </AuthenticationButton>
          </Box>
        </form>
      </Box>
    </Container>
  )
}

export default NewPasswordForm

import { Box, styled } from '@mui/material'
import {
  LoadingButton,
  LoadingButtonProps,
} from 'packs/dashboard/components/LoadingButton/LoadingButton'
import React from 'react'

const ButtonComponent = styled(LoadingButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: '#121112',
  fontFamily: 'Roboto',
  fontWeight: '400',
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '100px',
  opacity: '1 !important',
  '&:hover': {
    backgroundColor: '#252425',
  },
  '&[disabled]': {
    backgroundColor: '#F3F3F3',
    color: '#A7A8A9',
    pointerEvents: 'none',
  },
}))

const Wrapper = styled(Box)<{ disabled?: boolean }>(({ disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
  borderRadius: '100px',
  width: '100%',
}))

export const AuthenticationButton: React.FC<React.PropsWithChildren<LoadingButtonProps>> = ({
  children,
  isLoading = false,
  sx,
  ...props
}) => {
  return (
    <Wrapper disabled={props.disabled} sx={sx}>
      <ButtonComponent
        variant="contained"
        fullWidth
        disableElevation
        isLoading={isLoading}
        {...props}
      >
        {children}
      </ButtonComponent>
    </Wrapper>
  )
}

import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'

import { useStyles } from './SignUp.style'

interface SignUpProps {
  onClick?: () => void
}

export const SignUp: React.FC<React.PropsWithChildren<SignUpProps>> = (props) => {
  const { onClick } = props
  const styles = useStyles()

  return (
    <Box
      className={styles.header}
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 3,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ fontFamily: 'Open Sans', marginBottom: -0.25 }}
      >
        <Typography sx={{ fontSize: '1rem' }}>Don't have an account yet?</Typography>
        <div>
          <Button
            color="inherit"
            component="a"
            href="/register"
            onMouseDown={onClick}
            sx={{
              display: 'flex',
              fontSize: '1rem',
              fontWeight: 500,
              marginRight: -1,
              marginY: -1,
            }}
          >
            Sign Up
          </Button>
        </div>
      </Stack>
    </Box>
  )
}
